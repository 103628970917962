import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { InputAdornment, TextField, Button } from "@mui/material";
import { SOCIAL_MEDIA_URLS } from "../../../../../../constants/socialMedia";
import { setSocialLinksAction } from "../../../../../../store/middlewares/web/profile/myProfile/sociallinks";
import { closeModal } from "../../../../../../store/slices/common/modal";

const ModalChangeInstagram = () => {
  const dispatch = useDispatch();
  const { me } = useSelector((state) => state.me);
  const { BASE, PREFIX } = SOCIAL_MEDIA_URLS.INSTAGRAM;

  const formik = useFormik({
    initialValues: {
      instagram: me?.socialLinks?.find(link => link.type === "instagram")?.url?.replace(BASE + PREFIX, "") || "",
    },
    onSubmit: async (values) => {
      try {
        const newUrl = `${BASE}${PREFIX}${values.instagram}`;
        const result = await dispatch(setSocialLinksAction({ url: newUrl, type: "instagram" })).unwrap();
        if (result.status === 1) {
          dispatch(closeModal());
        }
      } catch (error) { }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        name="instagram"
        value={formik.values.instagram}
        onChange={formik.handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {BASE}{PREFIX}
            </InputAdornment>
          ),
        }}
      />
      <Button type="submit" variant="contained" className="mt-4 w-full">
        Save Changes
      </Button>
    </form>
  );
};

export default ModalChangeInstagram;
