import axiosClient from "../../../../utils/api";

// eslint-disable-next-line no-undef
const URI = process.env.REACT_APP_API_URL + "/admin/subscribtion";

export const getSubscriptions = async () => {
  const URL = `${URI}/getSubscriber`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Getting Banned User:", error);
    throw error;
  }
};
