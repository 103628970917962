import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const VaultSearchHeader = () => {
  return (
    <div className="post-tab bg-white rounded-2xl w-full">
      <Box
        sx={{
          display: 'flex',
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Vault
        </Typography>
        <IconButton>
          <SearchIcon />
        </IconButton>
      </Box>
    </div>
  );
};

export default VaultSearchHeader;
