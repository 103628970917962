import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnswerPollAction } from "../../../../../store/middlewares/web/post/posts";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { getFirstVotedId } from "../../../../../utils/post";
import GrayDot from "./../../../../../assets/images/dot.svg";
import { useOptionPostExpiry } from "../../../../../hooks/post/useOptionPostExpiry";
import { getColor } from "../../../../../utils/colors";

const DisplayPollInPost = ({ post }) => {
  const dispatch = useDispatch();
  const { me } = useSelector((state) => state.me);

  const { isPostExpired, timeLeftText } = useOptionPostExpiry(post?.expiry);

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [sortedOptions, setSortedOptions] = useState([]);

  const isPollDisabled =
    isPostExpired ||
    (post.expiry && post.expiry === null) ||
    (selectedAnswer !== null && selectedAnswer);

  useEffect(() => {
    if (post?.pollOptions?.length > 0) {
      const sorted = [...post.pollOptions].sort((a, b) => {
        const seqA = parseInt(a.sequence);
        const seqB = parseInt(b.sequence);
        return seqA - seqB;
      });
      setSortedOptions(sorted);
      setSelectedAnswer(getFirstVotedId(post?.pollOptions));
    }
  }, [post, me]);

  const handleChange = (e, optionId) => {
    setSelectedAnswer(e.target.value);
    dispatch(AnswerPollAction({ postId: post?.id, pollOptionId: optionId }));
  };
  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue={selectedAnswer}
      name="radio-buttons-group"
      className="mt-4"
    >
      {sortedOptions?.map((option, index) => {
        return (
          <FormControlLabel
            key={option.id}
            value={option.id}
            control={
              <Radio
                className="p-0"
                value={option.id}
                onChange={(e) => {
                  handleChange(e, option.id);
                }}
                checked={option?.id === selectedAnswer}
                disabled={!!isPollDisabled}
              />
            }
            label={
              <div className="flex justify-between items-center w-full relative">
                <div
                  className="absolute inset-0 rounded-lg"
                  style={{
                    width: `${option.percentage || 0}%`,
                    opacity: 0.3,
                    backgroundColor: getColor("secondary"),
                  }}
                />
                <p className="pl-2 relative z-10">{option.optionText}</p>

                {option.percentage !== null &&
                  option.percentage !== undefined && (
                    <span className="text-sm text-gray-500 relative z-10">
                      {option.votes !== null && option.votes !== undefined && (
                        <span>
                          {" "}
                          {option.votes} {option.votes > 1 ? "Votes" : "Vote"}
                        </span>
                      )}{" "}
                      ( {option.percentage}% )
                    </span>
                  )}
              </div>
            }
            className={`border ${
              selectedAnswer === option.optionText ? "border-[#2799F6]" : ""
            } p-2 rounded-lg ${index === 0 ? "mt-0" : "mt-2"} `}
          />
        );
      })}
      <div className="py-2 pl-3 rounded-b-lg light-gray-bg flex items-center mt-4">
        <p>POLL</p>
        {post?.expiry && (
          <img src={GrayDot} alt="GrayDot" className=" mx-2 gray-dot-width" />
        )}
        {isPostExpired && <p>Expired</p>}
        {!isPostExpired && <p>{timeLeftText}</p>}
      </div>
    </RadioGroup>
  );
};

export default DisplayPollInPost;
