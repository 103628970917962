import React, { useRef } from 'react';
import styles from './styles.module.css';

const Timeline = ({ currentTime, duration, onTimelineChange, formatTime }) => {
  const timelineRef = useRef(null);

  const handleTimelineClick = (e) => {
    const rect = timelineRef.current.getBoundingClientRect();
    const position = (e.clientX - rect.left) / rect.width;
    onTimelineChange(position * duration);
  };

  const progress = (currentTime / duration) * 100 || 0;

  return (
    <div className={styles.timelineContainer}>
      <div className={styles.timeLabels}>
        <span className={styles.timeLabel}>{formatTime(currentTime)}</span>
        <span className={styles.timeLabel}>{formatTime(duration)}</span>
      </div>
      
      <div 
        className={styles.timeline}
        ref={timelineRef}
        onClick={handleTimelineClick}
      >
        <div 
          className={styles.timelineProgress}
          style={{ width: `${progress}%` }}
        >
          <div className={styles.timelineHandle} />
        </div>
      </div>
    </div>
  );
};

export default Timeline;
