import { Avatar, AvatarGroup, IconButton } from "@mui/material";
import React from "react";
import CustomerImg1 from "./../../../../../assets/images/collections/customer1.png";
import { openModal } from "../../../../../store/slices/common/modal";
import { Delete } from "@mui/icons-material";
import listmenublack from "./../../../../../assets/images/listmenublack.svg";
import { useDispatch, useSelector } from "react-redux";
import ModalDeleteBookmark from "../ModalDeleteBookmark";
import NoPostsForBookmark from "../NoPostsForBookmark";

const BookMarkFolders = ({ active, setActive }) => {
  const { posts } = useSelector((state) => state.posts);
  const { folders } = useSelector((state) => state.folders);

  const dispatch = useDispatch();

  return (
    <div className="light-gray-bg p-4 creator-home-module">
      <div className="flex justify-between flex-wrap gap-3 border-b pb-3">
        <p className="font-18 font-medium">Recent</p>
        {/* <img
          src={listmenublack}
          alt="listmenublack"
          width={20}
          className="img-dark-filter"
        /> */}
      </div>

      {folders?.map((folder) => {
        return (
          <div
            className={`customer-box cursor-pointer ${
              active?.id === folder.id ? "active" : ""
            } mt-5`}
            key={folder.id}
            onClick={() => setActive(folder)}
          >
            <div className="flex justify-between gap-3 flex-wrap">
              <div>
                <p className="text-darkblue font-medium">
                  {folder?.folderName}
                </p>
                <AvatarGroup className="mt-3">
                  <Avatar
                    alt="CustomerImg1"
                    src={CustomerImg1}
                    className="avatar-img"
                  />
                </AvatarGroup>
              </div>
              <div className="flex gap-4 items-start">
                {/* <p className="font-14 font-medium">
                  {active?.id === folder?.id
                    ? folder?.bookmarksCount
                      ? folder?.bookmarksCount -
                        posts?.filter(
                          (post) => post?.bookmark_post[0]?.isActive !== true
                        )?.length
                      : 0
                    : folder?.bookmarksCount}{" "}
                  Posts
                </p> */}
                <IconButton
                  onClick={() =>
                    dispatch(
                      openModal({
                        title: "DELETE",
                        hasCloseBtn: true,
                        content: <ModalDeleteBookmark folderId={folder?.id} />,
                      })
                    )
                  }
                  disableRipple
                  sx={{ padding: "0" }}
                >
                  <Delete color="error" />
                </IconButton>
              </div>
            </div>
          </div>
        );
      })}
      {folders?.length === 0 && <NoPostsForBookmark />}
    </div>
  );
};

export default BookMarkFolders;
