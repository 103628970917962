import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { closeModal } from '../../../../../store/slices/common/modal';
import { createVaultFolderAction } from '../../../../../store/middlewares/web/vault/vault';

const validationSchema = Yup.object({
  folderName: Yup.string()
    .required('Folder name is required')
    .min(1, 'Folder name must be at least 1 character long')
    .max(255, 'Folder name cannot exceed 255 characters')
});

const ModalCreateFolder = () => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      folderName: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(createVaultFolderAction(values)).unwrap();
        dispatch(closeModal());
      } catch (error) {
        console.error('Failed to create folder:', error);
      }
    },
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ p: 2 }}>
      <TextField
        fullWidth
        name="folderName"
        placeholder="Enter vault folder name"
        value={formik.values.folderName}
        onChange={formik.handleChange}
        error={formik.touched.folderName && Boolean(formik.errors.folderName)}
        helperText={formik.touched.folderName && formik.errors.folderName}
        sx={{ mb: 2 }}
      />
      <Button
        fullWidth
        variant="contained"
        type="submit"
        disabled={formik.isSubmitting}
      >
        Create Vault Folder
      </Button>
    </Box>
  );
};

export default ModalCreateFolder;
