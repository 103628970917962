import React from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "../../../../../atoms/InfiniteScroll";

const LoadMoreComments = ({ comments, onLoadMore, loading }) => {
  const { isInitialCommentsLoaded } = useSelector((state) => state.posts);

  return (
    <InfiniteScroll
      onLoadMore={onLoadMore}
      loading={loading}
      isInitialDataLoaded={isInitialCommentsLoaded}
      dataLength={comments.length}
    />
  );
};

export default LoadMoreComments;
