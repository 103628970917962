import React from 'react';

const UnmuteButton = ({ width = 24, height = 24, color = "#ffffff", onClick }) => {
  return (
    <svg 
      width={width} 
      height={height} 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path d="M15.3 8.00677V6.95077C15.3 3.67277 13.023 2.41877 10.251 4.15677L7.039 6.16977C6.687 6.37877 6.28 6.49977 5.873 6.49977H4.3C2.1 6.49977 1 7.59977 1 9.79977V14.1998C1 16.3998 2.1 17.4998 4.3 17.4998H6.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path opacity="0.4" d="M10.251 19.8429C13.023 21.5809 15.3 20.3159 15.3 17.0489V13.0449" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path opacity="0.4" d="M19.4911 9.16211C20.4811 11.5271 20.1841 14.2881 18.6001 16.4001" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path opacity="0.4" d="M22.0648 7.37988C23.6818 11.2189 23.1978 15.7069 20.6128 19.1499" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23 1L1 23" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default UnmuteButton;
