import axiosClient from "../../../../utils/api";
// eslint-disable-next-line no-undef
const URI = process.env.REACT_APP_API_URL + "/admin/user";

export const getActiveUsers = async () => {
  const URL = `${URI}/getUsers`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during get Active Users Service:", error);
    throw error;
  }
};

export const getBlockedUsers = async () => {
  try {
    const response = await axiosClient().request({
      url: "/admin/block/getAll",
      method: "post",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during get Blocked Users Service:", error);
    throw error;
  }
};

export const getRestrictedUsers = async () => {
  try {
    const response = await axiosClient().request({
      url: "/admin/restricted/getAll",
      method: "post",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during get Restricted Users Service:", error);
    throw error;
  }
};

export const getBannedUsers = async () => {
  const URL = `${URI}/getDeletedUsers`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Getting Banned User:", error);
    throw error;
  }
};

export const banUser = async (payload) => {
  const URL = `${URI}/banUser`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    let { data } = response;
    return { id: payload.id, ...data };
  } catch (error) {
    console.error("Error occurred Banning User:", error);
    throw error;
  }
};

export const deleteUser = async (payload) => {
  const URL = `${URI}/deleteUser`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    let { data } = response;
    return { id: payload.id, ...data };
  } catch (error) {
    console.error("Error occurred during Delete User:", error);
    throw error;
  }
};

export const upsertUserToUserList = async (payload) => {
  const URL = "/userList/upsert";
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Upsert To Users List:", error);
    throw error;
  }
};

export const getUserListCollection = async (payload) => {

  const URL = "/userListFolder/get";
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get Users List:", error);
    throw error;
  }
};

export const getUserListCollectionUsers = async (payload) => {

  const URL = "/userListFolder/users/get";
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: {folderId: payload.folderId},
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get Users List:", error);
    throw error;
  }
};

export const getFilteredUserList = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: `/userList/users/get`,
      method: "get",
      params: {
        ...payload
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during get filtered users:", error);
    throw error;
  }
};