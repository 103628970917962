import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Skeleton, styled } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BlueCircleBg from "./../../../../../assets/images/blue-circle-bg.png";
import closesquare from "./../../../../../assets/images/close-square.svg";
import User1 from "./../../../../../assets/images/blank.png";
import LogoutIcon from "./../../../../../assets/images/logout.svg";
import IconButton from "@mui/material/IconButton";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ModalLogout from "../ModalLogout";
import sideBarItems from "../../../../../utils/sideBarItems";
import {
  personalSectionItems,
  walletSectionItems,
  toolsSectionItems,
} from "../../../../../utils/moreMenuItems";
import { openModal } from "../../../../../store/slices/common/modal";
import SettingsIcon from "../../../../../assets/SVG/SideBar/SettingsIcon";
import creatorSideBarItems from "../../../../../utils/creatorSideBarItems";
import { toggleDarkModeAction } from "../../../../../store/middlewares/web/profile/myProfile/profiledisplay";

const drawerWidth = 308;

export default function ResponsiveDrawer(props) {
  //----------------- Drawer Toggle ----------------
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const StyledListItemButton = styled(ListItemButton)(() => ({
    // marginRight: "0 !important",
    background: "#fff",
    boxShadow: "0 0 40px rgb(0 0 0 / 5%)",
    borderRadius: "10px",
    marginTop: "10px",
    paddingTop: "13px !important",
    paddingBottom: "13px !important",
    marginRight: "20px",
    "& .MuiListItemIcon-root": {
      minWidth: "40px",
    },
  }));

  const StyledListItemButtonPrimary = styled(ListItemButton)(() => ({
    paddingTop: "13px !important",
    paddingBottom: "13px !important",
    marginRight: "20px",
    "& .MuiListItemIcon-root": {
      // minWidth: "40px",
    },
    "& .MuiListItemText-primary": {
      fontSize: "18px",
    },
    "&.active .MuiListItemText-root": {
      fontWeight: 600,
      color: "#2799F6",
    },
  }));

  const StyledListTextPrimary = styled(ListItemText)(() => ({
    "& .MuiListItemText-primary": {
      fontSize: "16px !important",
      color: "#1E405C",
    },
  }));
  const StyledListTextSecondary = styled(ListItemText)(() => ({
    "& .MuiListItemText-primary": {
      fontSize: "14px !important",
      color: "#1E405C",
    },
  }));

  const { window, type } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [data, setData] = useState({
    name: "",
    username: "",
    profileImage: "",
  });

  const { me, meLoading } = useSelector((state) => state.me);

  useEffect(() => {
    if (me) {
      // eslint-disable-next-line no-undef
      setData({
        name: me?.name,
        username: me?.username,
        profileImage: me?.profileUrl
          ? `${me?.profileUrl}`
          : me?.profileImg
          ? // eslint-disable-next-line no-undef
            `${process.env.REACT_APP_AWS_URL}${me?.profileImg}`
          : "",
      });
    }
  }, [me]);

  const [activeItem, setActiveItem] = useState("");

  const handleNavigation = (slug) => {
    navigate(`/${slug}`); // todo edit slug
    setActiveItem(slug);
    toggleDrawer(false);
  };

  useEffect(() => {
    setActiveItem(pathname.split("/")[2]);
  }, [pathname]);

  const DrawerList = (
    //Drawer Open on More Button Click
    <Box
      className="bg-white h-full setting-drawer"
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <div className="closeIcon mt-2 pr-2">
        <IconButton color="primary" aria-label="add to shopping cart">
          <img
            src={closesquare}
            alt="closesquare"
            width={24}
            height={24}
            className="img-dark-filter"
          />
        </IconButton>
      </div>
      <List className="px-4 setting-list sidebar">
        <div className="bluebg w-full">
          <div className="flex gap-3 relative w-full">
            <img src={BlueCircleBg} alt="BlueCircleBg" />
            <div className="text-white BlueCircleBg-position w-full">
              <div className="flex flex-wrap gap-3 sidemenu-more-profile">
                {meLoading ? (
                  <Skeleton width={60} height={60} variant="circular" />
                ) : (
                  <img
                    src={data?.profileImage ? data?.profileImage : User1}
                    width={60}
                    style={{ borderRadius: "50%", objectFit: "cover" }}
                    alt="User"
                  />
                )}
                <div>
                  {meLoading ? (
                    <Skeleton width={184} height={27} variant="text" />
                  ) : (
                    <h4 className="font-semibold font-20">{data?.name}</h4>
                  )}
                  {meLoading ? (
                    <Skeleton width={184} height={27} variant="text" />
                  ) : data?.username ? (
                    <p className="font-14">@{data?.username}</p>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>

              <div className="flex gap-3 mt-4 w-full">
                <Button
                  sx={{ minHeight: "47px !important" }}
                  variant="contained"
                  className="offwhitebtn w-full"
                >
                  {me?.fanCount ? me?.fanCount : 0} Fans
                </Button>
                <Button
                  sx={{ minHeight: "47px !important" }}
                  variant="contained"
                  className="offwhitebtn w-full"
                >
                  {me?.followingCount ? me?.followingCount : 0} Following
                </Button>
              </div>
            </div>
          </div>
        </div>
        <h5 className="font-20 text-darkblue font-semibold mt-4">Personal</h5>
        {personalSectionItems?.map((item) => {
          return (
            <StyledListItemButton
              className={
                activeItem === item?.slug || pathname.includes(item?.slug)
                  ? "active"
                  : ""
              }
              key={item?.label}
              onClick={() => {
                if (item?.hasPage) {
                  handleNavigation(item?.slug);
                  toggleDrawer(false);
                }
              }}
            >
              <ListItemIcon>{item?.icon}</ListItemIcon>
              <StyledListTextPrimary primary={item?.label} />
            </StyledListItemButton>
          );
        })}
        {type !== "creator" && (
          <StyledListItemButton
            className={
              activeItem === "become-a-creator" ||
              pathname.includes("become-a-creator")
                ? "active"
                : ""
            }
            onClick={() => {
              handleNavigation("become-a-creator");
              toggleDrawer(false);
            }}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <StyledListTextPrimary primary={"Become A Creator"} />
          </StyledListItemButton>
        )}
        <h5 className="font-20 text-darkblue font-semibold mt-4">Wallet</h5>
        {walletSectionItems?.map((item) => {
          return (
            <StyledListItemButton key={item?.label}>
              <ListItemIcon>{item?.icon}</ListItemIcon>
              <StyledListTextPrimary primary={item?.label} />
              <StyledListTextSecondary
                className="text-end font-14 text-black"
                primary={item?.helperText}
              />
            </StyledListItemButton>
          );
        })}
        <h5 className="font-20 text-darkblue font-semibold mt-4">Tools</h5>
        {toolsSectionItems?.map((item) => {
          return (
            <StyledListItemButton
              key={item?.label}
              onClick={() => {
                if (item?.slug === "dark-mode") {
                  dispatch(toggleDarkModeAction({ mode: me?.isDarkMode }));
                }
              }}
            >
              <ListItemIcon>{item?.icon}</ListItemIcon>
              <StyledListTextPrimary primary={item?.label} />
            </StyledListItemButton>
          );
        })}

        <div className="w-4/6 m-auto my-8">
          <Button
            sx={{ minHeight: "47px !important" }}
            variant="contained"
            className="red-btn w-full"
            onClick={() =>
              dispatch(
                openModal({
                  title: "Logout",
                  content: <ModalLogout />,
                  hasCloseBtn: true,
                })
              )
            }
          >
            Log Out <img src={LogoutIcon} alt="LogoutIcon" />
          </Button>
        </div>
      </List>
    </Box>
  );

  const drawer = (
    //main Drawer
    <div className="flex flex-col gap-[0.75rem]">
      <div className="bg-white p-2 md:p-4 rounded-2xl sidebar-user-box flex items-center justify-center md:justify-start  gap-3">
        <div className="flex ">
          {meLoading ? (
            <Skeleton width={60} height={60} variant="circular" />
          ) : (
            <img
              src={data?.profileImage ? data?.profileImage : User1}
              alt="User"
            />
          )}
        </div>
        <div className="user-data-container md:block hidden">
          {meLoading ? (
            <Skeleton width={184} height={27} variant="text" />
          ) : (
            <h3>{data?.name}</h3>
          )}
          {meLoading ? (
            <Skeleton width={184} height={27} variant="text" />
          ) : data?.username ? (
            <p>@{data?.username}</p>
          ) : (
            <p></p>
          )}
        </div>
      </div>

      <div className="bg-white pt-0 md:pt-4 pb-4 rounded-2xl mt-0 md:mt-4">
        <List
          sx={{ width: "100%", maxWidth: 360 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <>
            {type === "user" && (
              <>
                {sideBarItems()?.map((item) => {
                  if (item?.slug !== "more") {
                    return (
                      <StyledListItemButtonPrimary
                        key={item?.slug}
                        className={
                          activeItem === item?.slug ||
                          pathname.includes(item?.slug)
                            ? "active"
                            : ""
                        }
                        onClick={() => {
                          if (item?.hasPage === true) {
                            handleNavigation(item?.slug);
                          }
                        }}
                      >
                        <ListItemIcon>{item?.icon}</ListItemIcon>
                        <ListItemText
                          primary={item?.label}
                          className="md:block hidden"
                        />
                        {item?.count !== 0 && (
                          <span className="badge md:block hidden">
                            <span className="top1">{item?.count}</span>
                          </span>
                        )}
                      </StyledListItemButtonPrimary>
                    );
                  }
                  return (
                    <StyledListItemButtonPrimary
                      key={item?.slug}
                      onClick={toggleDrawer(true)}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        primary={item?.label}
                        className="md:block hidden"
                      />
                    </StyledListItemButtonPrimary>
                  );
                })}
              </>
            )}
            {type === "creator" && (
              <>
                {creatorSideBarItems()?.map((item) => {
                  if (item?.slug !== "more") {
                    return (
                      <StyledListItemButtonPrimary
                        key={item?.slug}
                        className={
                          activeItem === item?.slug ||
                          pathname.includes(item?.slug)
                            ? "active"
                            : ""
                        }
                        onClick={() => {
                          if (item?.hasPage === true) {
                            handleNavigation(item?.slug);
                          }
                        }}
                      >
                        <ListItemIcon>{item?.icon}</ListItemIcon>
                        <ListItemText primary={item?.label} />
                        {item?.count !== 0 && (
                          <span className="badge">
                            <span className="top1">{item?.count}</span>
                          </span>
                        )}
                      </StyledListItemButtonPrimary>
                    );
                  }
                  return (
                    <StyledListItemButtonPrimary
                      key={item?.slug}
                      onClick={toggleDrawer(true)}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText primary={item?.label} />
                    </StyledListItemButtonPrimary>
                  );
                })}
              </>
            )}
          </>
          <div className="px-3 mt-[1rem] md:mt-1 flex justify-center">
            <Button
              // sx={{ minHeight: "47px !important" }}
              variant="contained"
              className="w-full"
              onClick={() => navigate(`/new-post`)}
            >
              <span className="md:block hidden">New Post</span>
              <ControlPointIcon className="md:ml-2 ml-0" />
            </Button>
          </div>
        </List>
      </div>

      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className="sidebar mr-6">
      <Box
        sx={{
          position: "sticky",
          top: 0,
          height: "100vh",
          overflowY: "auto",
          backgroundColor: "transparent",
        }}
        className="sidebar-sticky"
      >
        <CssBaseline />

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
          {drawer}
        </Box>
      </Box>
    </div>
  );
}
