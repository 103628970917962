import axiosClient from "../../../../utils/api";

const generateMediaUploadPreSignedUrl = async (data) => {
  try {
    const response = await axiosClient().request({
      url: "/vault/generateMediaUploadPreSignedUrl",
      method: "post",
      data,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Generate Media PreSigned URL Service", error);
    throw error;
  }
};

const addToVault = async (data) => {
  try {
    const response = await axiosClient().request({
      url: "/vault/addToVault",
      method: "post",
      data,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Add To Vault Service", error);
    throw error;
  }
};

const getVaultMedia = async (params) => {
  try {
    const response = await axiosClient().request({
      url: "/vault",
      method: "get",
      params,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get Vault Media Service", error);
    throw error;
  }
};

const getAllVaultFolders = async () => {
  try {
    const response = await axiosClient().request({
      url: "/vault/folder/all",
      method: "get"
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get All Vault Folders Service", error);
    throw error;
  }
};

const createFolder = async (data) => {
  try {
    const response = await axiosClient().request({
      url: "/vault/folder",
      method: "post",
      data,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Create Vault Folder Service", error);
    throw error;
  }
};

const vaultService = {
  generateMediaUploadPreSignedUrl,
  addToVault,
  getVaultMedia,
  getAllVaultFolders,
  createFolder,
};

export default vaultService;