import React from "react";
import { Box, List } from "@mui/material";
import VaultCard from "../VaultCard";

const VaultList = ({ items, onFolderSelect }) => {
  return (
    <Box
      sx={{
        p: 2,
        pt: 0,
        overflowY: "auto",
        maxHeight: "calc(100vh - 120px)",
      }}
    >
      <List sx={{ pt: 0 }}>
        {items.map((item) => (
          <VaultCard
            key={item.id}
            isSelected={item.isSelected}
            vaultCollectionName={item.name}
            gifCount={item.gifCount}
            videoCount={item.videoCount}
            photoCount={item.photoCount}
            audioCount={item.audioCount}
            avatars={item.avatars}
            onClick={() => onFolderSelect(item.id)}
          />
        ))}
      </List>
    </Box>
  );
};

export default VaultList;
