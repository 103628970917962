/**
 * Formats a duration in seconds to a MM:SS string format
 * @param {number} seconds - The duration in seconds
 * @returns {string} Formatted duration string in MM:SS format
 */
export const formatDuration = (seconds) => {
  if (!seconds) return '';
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

/**
 * Formats a duration in seconds to a HH:MM:SS string format
 * @param {number} seconds - The duration in seconds
 * @returns {string} Formatted duration string in HH:MM:SS format
 */
export const formatSeconds = (seconds) => {
  if (!seconds) return "00:00:00";
  
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return [hours, minutes, remainingSeconds]
    .map(val => val.toString().padStart(2, '0'))
    .join(':');
};

export default formatDuration;
