import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Logo from "../../../assets/images/logo.svg";
import Button from "@mui/material/Button";
import EmailIcon from "../../../assets/images/email-icon.svg";
import LockIcon from "../../../assets/images/lock.svg";
import EyeIconslash from "../../../assets/images/eye-slash.svg";
import EyeIcon from "../../../assets/images/eye.svg";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Password, email } from "../../../utils/validation/LoginForm";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { resetwebregister } from "../../../store/slices/auth/register/register.slice";
import { resetwebforgot } from "../../../store/slices/auth/forgotPassword/forgot.slice";
import { resetwebreset } from "../../../store/slices/auth/reset/reset.slice";
import { resetwebotp } from "../../../store/slices/auth/forgotPassword/otp.slice";
import { resetweblogin } from "../../../store/slices/auth/session/auth.slice";
import { SocialMediaButtons } from "../../../newComponents/molecules";
import { Input } from "../../../newComponents/atoms";
import {
  loginAction,
  verifyIdentityAction,
} from "../../../store/middlewares/auth/session/auth";
import axios from "axios";
import { languages } from "../../../utils/languages";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const { webloginMessage, webloggedInUser, webloginError, hasTwoFa } =
    useSelector((state) => state.webauth);

  // ---------------------- Verify token-------------------------------------

  useEffect(() => {
    if (token) {
      dispatch(verifyIdentityAction(token));
    }
  }, [token, dispatch]);

  // ---------------------- Get ip address-------------------------------------
  const [ip, setIP] = useState("");

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  // ---------------------- Password hide show --------------------------------

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // ---------------------- validation --------------------------------
  const handleSubmit = (values) => {
    const payload = {
      email: values.email,
      password: values.password,
      type: "null",
      ipAddress: ip,
    };
    dispatch(loginAction(payload));
  };

  const LoginSchema = Yup.object({
    email: email,
    password: Password,
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: LoginSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  //------------------------ navigation --------------------
  useEffect(() => {
    dispatch(resetwebregister());
    dispatch(resetwebforgot());
    dispatch(resetwebreset());
    dispatch(resetwebotp());
    dispatch(resetweblogin());
  }, [dispatch]);

  //Navigate To TOTP Screen
  useEffect(() => {
    if (hasTwoFa) {
      navigate("/verify-totp");
    }
  }, [hasTwoFa, navigate]);

  useEffect(() => {
    if (webloggedInUser) navigate("/home");
  }, [webloggedInUser, navigate]);

  //------------ if formik error show that time reset the login slice --------------------
  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      dispatch(resetweblogin());
    }
  }, [formik.errors, dispatch]);

  const handleChange = (field) => (e) => {
    formik.setFieldTouched(field, false);
    formik.setFieldError(field, "");
    formik.handleChange(e);
  };

  const wrapperClasses =
    "flex justify-center flex-col h-full p-8 lg:pl-16 md:pl-16";

  return (
    <div className="auth-page">
      <Grid container spacing={2} className="h-full">
        <Grid item xs={6} md={6} lg={8} className="hidden md:block">
          <div className="auth-bg"></div>
        </Grid>
        <Grid item xs={12} md={6} lg={4} className="max-h-auto">
          <Box className={wrapperClasses}>
            <img
              src={Logo}
              alt="logo"
              className="mb-16 mx-auto pointer-events-none"
            />
            <h1>{languages["en"].LogIn}</h1>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-6 w-full">
                <Input
                  placeholder="Your Email"
                  className={
                    formik.errors.email && formik.touched.email
                      ? "error-border"
                      : ""
                  }
                  error={formik.errors.email && formik.touched.email}
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleChange("email")}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  errorMsg={
                    formik.errors.email
                      ? formik.errors.email
                      : !webloginMessage?.toLowerCase().includes("password")
                      ? webloginMessage
                      : ""
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <img src={EmailIcon} alt="EmailIcon" />{" "}
                    </InputAdornment>
                  }
                />
              </div>
              <div className="mb-8 w-full">
                <Input
                  placeholder="Your Password"
                  name="password"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange("password")}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={formik.errors.password && formik.touched.password}
                  className={
                    formik.errors.password && formik.touched.password
                      ? "error-border"
                      : ""
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <img src={LockIcon} alt="LockIcon" />{" "}
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <img
                        src={showPassword ? EyeIcon : EyeIconslash}
                        alt="EyeIcon"
                        onClick={handleTogglePasswordVisibility}
                        className="cursor-pointer"
                      />
                    </InputAdornment>
                  }
                  errorMsg={
                    formik.errors.password
                      ? formik.errors.password
                      : webloginMessage?.toLowerCase().includes("password")
                      ? webloginMessage
                      : ""
                  }
                />
              </div>
              {!!webloginError && (
                <div className={`mb-8 w-full text-red-500`}>
                  {webloginMessage}
                </div>
              )}
              <Button type="submit" variant="contained" className="w-full">
                {languages["en"].LogIn}
              </Button>
            </form>
            <div className="text-right mt-4">
              <NavLink to="/forgotpassword">
                {languages["en"].ForgotPassword}?
              </NavLink>
            </div>

            <div className="separator mt-8">
              <div className="line"></div>
              <p>or</p>
              <div className="line"></div>
            </div>

            <SocialMediaButtons ip={ip} />

            <p className="para mt-16 mb-6">
              {languages["en"].NoAccount}{" "}
              <NavLink to="/register" className="text-blue font-bold">
                {languages["en"].Register}
              </NavLink>
            </p>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
