import React from "react";

export const StarIcon = ({
  color = undefined,
  size = 24,
  type = "outline", //filled, outline
}) => {
  if (type === "outline") {
    return (
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.7289 3.51063L15.4889 7.03063C15.7289 7.52063 16.3689 7.99063 16.9089 8.08063L20.0989 8.61062C22.1389 8.95062 22.6189 10.4306 21.1489 11.8906L18.6689 14.3706C18.2489 14.7906 18.0189 15.6006 18.1489 16.1806L18.8589 19.2506C19.4189 21.6806 18.1289 22.6206 15.9789 21.3506L12.9889 19.5806C12.4489 19.2606 11.5589 19.2606 11.0089 19.5806L8.01893 21.3506C5.87893 22.6206 4.57893 21.6706 5.13893 19.2506L5.84893 16.1806C5.97893 15.6006 5.74893 14.7906 5.32893 14.3706L2.84893 11.8906C1.38893 10.4306 1.85893 8.95062 3.89893 8.61062L7.08893 8.08063C7.61893 7.99063 8.25893 7.52063 8.49893 7.03063L10.2589 3.51063C11.2189 1.60063 12.7789 1.60063 13.7289 3.51063Z"
          stroke={color ?? "#2799F6"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.7289 3.51063L15.4889 7.03063C15.7289 7.52063 16.3689 7.99063 16.9089 8.08063L20.0989 8.61062C22.1389 8.95062 22.6189 10.4306 21.1489 11.8906L18.6689 14.3706C18.2489 14.7906 18.0189 15.6006 18.1489 16.1806L18.8589 19.2506C19.4189 21.6806 18.1289 22.6206 15.9789 21.3506L12.9889 19.5806C12.4489 19.2606 11.5589 19.2606 11.0089 19.5806L8.01893 21.3506C5.87893 22.6206 4.57893 21.6706 5.13893 19.2506L5.84893 16.1806C5.97893 15.6006 5.74893 14.7906 5.32893 14.3706L2.84893 11.8906C1.38893 10.4306 1.85893 8.95062 3.89893 8.61062L7.08893 8.08063C7.61893 7.99063 8.25893 7.52063 8.49893 7.03063L10.2589 3.51063C11.2189 1.60063 12.7789 1.60063 13.7289 3.51063Z"
          fill={color ?? "#2799F6"}
        />
      </svg>
    );
  }
};
