import { Button } from "@mui/material";
import React from "react";
import { HomeSingleSuggestionMenu } from "./../../..";
import User1 from "../../../../../assets/images/blank.png";
import { awsImageLoader } from "../../../../../utils/awsImageLoader";

const HomeSingleSuggestion = ({ suggestion }) => {
  //Right Side In Home Page
  return (
    <div className="relative md:pr-2 pr-0 mt-3 w-full h-[224px]">
      <img
        src={suggestion?.coverImg ? awsImageLoader(suggestion.coverImg) : User1}
        alt="User12"
        className="w-full h-full object-cover rounded-[16px]"
      />
      <HomeSingleSuggestionMenu suggestion={suggestion} />
      <div className="thumbnail-text">
        <div className="flex gap-2 justify-between items-center">
          <a
            href={`/profile/${suggestion.username}`}
            className="flex gap-2"
          >
            <img
              src={
                suggestion?.profileImage
                  ? awsImageLoader(suggestion.profileImage)
                  : User1
              }
              alt="ThumbnailUser1"
              className="thumbnailuser1"
            />
            <div className="flex flex-col justify-center text-white">
              <h3>{suggestion?.name}</h3>
              {suggestion?.username && <p>@{suggestion?.username}</p>}
            </div>
          </a>
          {suggestion?.hasFreePlan && (
            <Button
              variant="contained"
              className="btn-white"
              component="div"
              disableRipple
              sx={{
                cursor: "default",
                pointerEvents: "none",
              }}
            >
              Free
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeSingleSuggestion;
