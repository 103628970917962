import React from 'react';
import { TextField, InputAdornment } from '@mui/material';

const Input = ({
  name,
  value = "",
  onChange,
  placeholder,
  type = "text",
  disabled = false,
  error = false,
  errorMsg = "",
  onBlur,
  id,
  startAdornment,
  endAdornment,
  ...props
}) => {
  const inputValue = value ?? "";

  return (
    <div className="w-full">
      <TextField
        id={id}
        name={name}
        value={inputValue}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        error={error}
        fullWidth
        InputProps={{
          startAdornment: startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : null,
          endAdornment: endAdornment || null,
        }}
        {...props}
      />
      {error && errorMsg && (
        <p className="mt-1 text-sm text-red-500">{errorMsg}</p>
      )}
    </div>
  );
};

export default Input;
