import React from 'react';

const BackwardsButton = ({ width = 28, height = 28, color = "white", onClick }) => {
  return (
    <svg 
      width={width} 
      height={height} 
      viewBox="0 0 28 28" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path d="M25.6667 9.73001V18.27C25.6667 20.02 23.765 21.1167 22.2483 20.2417L18.55 18.1183L14.8517 15.9834C14.6183 15.8434 14.4317 15.6917 14.28 15.505V12.5184C14.4317 12.3317 14.6183 12.18 14.8517 12.04L18.55 9.905L22.2483 7.7817C23.765 6.88336 25.6667 7.98001 25.6667 9.73001Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.28 9.73001V18.27C14.28 20.02 12.3783 21.1167 10.8617 20.2417L7.16334 18.1183L3.46499 15.9834C1.94833 15.1084 1.94833 12.9267 3.46499 12.04L7.16334 9.905L10.8617 7.7817C12.3783 6.88336 14.28 7.98001 14.28 9.73001Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default BackwardsButton;
