import {
  deleteComment,
  addComment,
  likeDislikePosts,
  getPosts,
  createPosts,
  upsertBookmark,
  getAllPosts,
  getFolderWisePosts,
  getPostsByUserIdWebsite,
  getPostsByUserId,
  getAllPostsAdmin,
  deletePostAdmin,
  banPostAdmin,
  getDeletedPostsAdmin,
  hidePostsOfAUser,
  restrictUserFromPost,
  getPostFromId,
  answerPoll,
  answerQuiz,
  deletePost,
  getStories,
  getPostComments,
  updateUserPost,
  getScheduledPosts,
  getArchivedPostsWithCursor,
  deleteBookmark,
} from "../../../services/web/post/posts.services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeModal } from "../../../slices/common/modal";
import { getUserListCollection } from "../../../services/admin/users/users";
import { openToast } from "../../../slices/common/toast";
import { RestrictSuggestionAction } from "../../../slices/web/suggestion/suggestion.slice";
import { RestrictUserAction } from "../../../slices/common/users/user";
import { RestrictUsersAction } from "../../../slices/common/users";
import {
  bookmarkUpdateCount,
  updateCountOnRestrictUser,
} from "../../../slices/web/folders/folders.slice";
import {
  getPostsWithCursor,
  getActiveExplorePosts,
} from "../../../services/web/post/posts.services";

export const getPostsAction = createAsyncThunk(
  "posts/getPosts",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getPosts();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPostWebsiteAction = createAsyncThunk(
  "posts/getPost",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getPostFromId(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getActiveExplorePostsAction = createAsyncThunk(
  "posts/getActiveExplorePosts",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getActiveExplorePosts(payload);
      if (response.status === 1) {
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPostsWithCursorAction = createAsyncThunk(
  "posts/getPostsWithCursor",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      let response;
      if (payload?.userId) {
        response = await getPostsByUserIdWebsite(payload);
      } else if (payload?.isScheduled) {
        delete payload.isScheduled;
        response = await getScheduledPosts(payload);
      } else if (payload?.archived) {
        delete payload.archived;
        response = await getArchivedPostsWithCursor(payload);
      } else {
        response = await getPostsWithCursor(payload);
      }
      // const response = await getPostsWithCursor(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue({ ...response });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPostsByUserIdWebsiteAction = createAsyncThunk(
  "posts/getPostsByUserIdWebsite",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getPostsByUserIdWebsite(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPostsByUserIdAdmin = createAsyncThunk(
  "posts/getPostsByUserIdAdmin",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getPostsByUserId(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFolderWisePostsAction = createAsyncThunk(
  "posts/getFolderWisePosts",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getFolderWisePosts(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const hidePostsOfAUserAction = createAsyncThunk(
  "posts/hidePostsOfAUserAction",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await hidePostsOfAUser(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        if (payload.type === "unhide") {
          location.reload();
        }
        return fulfillWithValue({ ...response, ...payload });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addPost = createAsyncThunk(
  "posts/addPosts",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      // let { files, uploadFor, ...rest } = payload;
      const files =
        payload.files && payload.files.length > 0 ? payload.files : null;
      delete payload.files;
      const response = await createPosts(payload, files);
      if (response.status === 1) {
        return fulfillWithValue(response);
      }
      // if any async operation need to be done on response data, do it here,
      // then full fill data
      // dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const likeDislikePost = createAsyncThunk(
  "posts/likePosts",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await likeDislikePosts(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addComentsToPost = createAsyncThunk(
  "posts/addComments",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await addComment(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeCommentsAction = createAsyncThunk(
  "posts/removeComments",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await deleteComment(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addToBookMarks = createAsyncThunk(
  "posts/addToBookMark",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { folderId, ...rest } = payload;
      const response = await upsertBookmark(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        if (rest.flag === false) {
          dispatch(bookmarkUpdateCount({ folderId }));
        }
        dispatch(closeModal());
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeFromBookmarks = createAsyncThunk(
  "posts/removeFromBookmarks",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await deleteBookmark(payload);
      if (response.status === 1) {
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const GetAllPostAdminAction = createAsyncThunk(
  "posts/getAllPostsAdmin",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllPostsAdmin();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const GetDeletedPostAdminAction = createAsyncThunk(
  "posts/getDeletedPostsAdmin",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getDeletedPostsAdmin();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Delete Post For Admin
export const DeletePostAdmin = createAsyncThunk(
  "posts/deletePostAdmin",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deletePostAdmin(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeModal());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue({ ...response, postId: payload.postId });
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

//Delete Post For Web
export const DeletePost = createAsyncThunk(
  "posts/deletePost",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deletePost(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeModal());
        return fulfillWithValue({ ...response, postId: payload.postId });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Ban Post For Admin
export const BanPostAdmin = createAsyncThunk(
  "posts/banPostAdmin",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await banPostAdmin(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeModal());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const getPostsCollectionForUserListAction = createAsyncThunk(
  "users/getPostsCollectionForUserList",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getUserListCollection({
        ...payload,
        dataType: "post",
      });
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const RestrictUserFromPostAction = createAsyncThunk(
  "posts/restrictUsers",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { userId, ...rest } = payload;
      const response = await restrictUserFromPost({ userId });
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(RestrictSuggestionAction({ id: userId }));
        dispatch(RestrictUserAction({ id: userId }));
        dispatch(RestrictUsersAction({ id: userId }));
        if (rest) {
          dispatch(updateCountOnRestrictUser({ ...rest }));
        }
        return fulfillWithValue({ ...response, id: userId });
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const AnswerPollAction = createAsyncThunk(
  "posts/answerPoll",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await answerPoll(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue({ ...response, id: payload.id });
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const AnswerQuizAction = createAsyncThunk(
  "posts/answerQuiz",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await answerQuiz(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue({ ...response, id: payload.id });
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const getStoriesAction = createAsyncThunk(
  "posts/getStories",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getStories();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPostCommentsAction = createAsyncThunk(
  "posts/getPostComments",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getPostComments(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePost = createAsyncThunk(
  "posts/updatePost",
  async ({ id, data }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const payload = { id, data };
      const response = await updateUserPost(payload);
      if (response.status === 1) {
        dispatch(closeModal());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Edit Comment For Post
// export function UpdateComment(payload) {
//     return (dispatch) => {
//         dispatch(updatePostsRequest())
//         let { commentId, postId, comment } = payload
//         updateComment({ commentId, comment })
//             .then((res) => {
//                 const { response, status, message } = res;
//                 if (status === 1) {
//                     dispatch(updateCommentsSuccess({ data: response, message, postId: postId, commentId: commentId }));
//                 } else {
//                     dispatch(updatePostsError({ message }));
//                 }
//             })
//             .catch((error) => {
//                 dispatch(updatePostsError({ message: error?.response?.data?.message }));
//             });
//     };
// }

//Get User wise Post For Admin
// export function GetPostByUserId(payload) {
//     return (dispatch) => {
//         dispatch(postsRequest())
//         getPostsByUserId(payload)
//             .then((res) => {
//                 const { response, status, message } = res;
//                 if (status === 1) {
//                     dispatch(postsSuccess({ data: response, message }));
//                 } else {
//                     dispatch(postsError({ message }));
//                 }
//             })
//             .catch((error) => {
//                 dispatch(postsError({ message: error?.response?.data?.message }));
//             });
//     };
// }

//Ban Post For Admin
// export function BanPost(payload) {
//     return (dispatch) => {
//         dispatch(postsRequest())
//         deletePostAdmin(payload)
//             .then((res) => {
//                 const { response, status, message } = res;
//                 if (status === 1) {
//                     dispatch(postsSuccess({ data: response, message }));
//                 } else {
//                     dispatch(postsError({ message }));
//                 }
//             })
//             .catch((error) => {
//                 dispatch(postsError({ message: error?.response?.data?.message }));
//             });
//     };
// }
