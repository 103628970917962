import React from 'react';
import styles from './styles.module.css';
import OptionsButton from '../../../../../../assets/SVG/media/videoPlayer/OptionsButton';
import MiniplayerButton from '../../../../../../assets/SVG/media/videoPlayer/MiniplayerButton';
import FullScreenButton from '../../../../../../assets/SVG/media/videoPlayer/FullScreenButton';

const OptionsControls = ({ isFullscreen, isMiniplayer, onFullscreenToggle, onMiniplayerToggle, size }) => {
  return (
    <div className={styles.optionsControls}>
      <button className={styles.controlButton}>
        <OptionsButton height={size} width={size}/>
      </button>

      <button 
        className={`${styles.controlButton} ${isMiniplayer ? styles.active : ''}`}
        onClick={onMiniplayerToggle}
      >
        <MiniplayerButton height={size} width={size}/>
      </button>

      <button 
        className={`${styles.controlButton} ${isFullscreen ? styles.active : ''}`}
        onClick={onFullscreenToggle}
      >
        <FullScreenButton height={size} width={size}/>
      </button>
    </div>
  );
};

export default OptionsControls;
