import { Grid } from '@mui/material'
import React from 'react'
import PushNotification from './../../../../../assets/images/push-notification.svg';
import SiteNotification from './../../../../../assets/images/site-notification.svg';
import ToastNotification from './../../../../../assets/images/toast-notification.svg';
import Telegram from './../../../../../assets/images/telegram.svg';
import ProfileMail from './../../../../../assets/images/profile-email.svg';
import { useDispatch, } from 'react-redux';
import { ProfileButtons, ProfileSectionWrapper } from '../../../../atoms';
import { ModalEmailNotifications, ModalPushNotifications, ModalSiteNotifications, ModalTelegramBot, ModalToastNotifications } from '../../../../molecules';
import { openModal } from '../../../../../store/slices/common/modal';

const ProfileNotifications = () => {
  // Notifications Tab in My Profile Section
  const dispatch = useDispatch()

  const handleOpenDialog = (name) => {
    if (name === "push-notification") {
      dispatch(openModal({
        title: 'PUSH NOTIFICATIONS',
        content: <ModalPushNotifications  />,
        hasCloseBtn: true
      }))
    }
    if (name === "email-notification") {
      dispatch(openModal({
        title: 'EMAIL NOTIFICATIONS',
        content: <ModalEmailNotifications  />,
        hasCloseBtn: true
      }))
    }
    if (name === "site-notification") {
      dispatch(openModal({
        title: 'SITE NOTIFICATIONS',  
        content: <ModalSiteNotifications />,
        hasCloseBtn: true
      }))
    }
    if (name === "toast-notification") {
      dispatch(openModal({
        title: 'TOAST NOTIFICATIONS',
        content: <ModalToastNotifications />,
        hasCloseBtn: true
      }))
    }
    if (name === "telegram-bot") {
      dispatch(openModal({
        title: 'TELEGRAM BOT',
        content: <ModalTelegramBot />,
        hasCloseBtn: true
      }))
    }

  }


  return (
    <Grid container spacing={2} className='fans-profile mt-3'>
      <Grid item xs={12} md={12} lg={12} className='mb-4'>
        <div className='bg-white rounded-2xl py-3 px-5 h-calc-200'>
          <h3 className='font-semibold text-darkblue font-24 mb-3'>Notifications</h3>

          <ProfileSectionWrapper title={"Additional Info"}>
            <Grid container spacing={2} className='fans-profile account-info'>
              <ProfileButtons endAdornment={<img src={PushNotification} alt='PushNotification' onClick={() => handleOpenDialog("push-notification")} />} title={"Push Notifications"} hasOneValue={false} />
              <ProfileButtons endAdornment={<img src={ProfileMail} alt='Profile Mail' onClick={() => handleOpenDialog("email-notification")} />} title={"Email Notifications"} hasOneValue={false} />
              <ProfileButtons endAdornment={<img src={SiteNotification} alt='SiteNotification' onClick={() => handleOpenDialog("site-notification")} />} title={"Site Notifications"} hasOneValue={false} />
              <ProfileButtons endAdornment={<img src={ToastNotification} alt='ToastNotification' onClick={() => handleOpenDialog("toast-notification")} />} title={"Toast Notifications"} hasOneValue={false} />
            </Grid>
          </ProfileSectionWrapper>

          {/* <ProfileSectionWrapper title={"Other"}>
            <Grid container spacing={2} className='fans-profile account-info'>
              <ProfileButtons endAdornment={<img src={Telegram} alt='Telegram' onClick={() => handleOpenDialog("telegram-bot")} />} title={"Telegram Bot"} hasOneValue={false} />
            </Grid>
          </ProfileSectionWrapper> */}
        </div>
      </Grid>
    </Grid>
  )
}

export default ProfileNotifications
