export const mockMediaItems = [
  {
    id: 1,
    type: "video",
    thumbnail: "https://via.placeholder.com/150",
    duration: "01:23",
    date: "Feb 26",
    selected: false,
  },
  {
    id: 2,
    type: "image",
    thumbnail: "https://via.placeholder.com/150",
    duration: null,
    date: "Feb 24",
    selected: false,
  },
  {
    id: 3,
    type: "audio",
    thumbnail: "https://via.placeholder.com/150",
    duration: "02:45",
    date: "Feb 22",
    selected: false,
  },
  {
    id: 4,
    type: "gif",
    thumbnail: "https://via.placeholder.com/150",
    duration: null,
    date: "Feb 20",
    selected: false,
  },
  {
    id: 5,
    type: "video",
    thumbnail: "https://via.placeholder.com/150",
    duration: "03:15",
    date: "Feb 18",
    selected: true,
  },
  {
    id: 6,
    type: "image",
    thumbnail: "https://via.placeholder.com/150",
    duration: null,
    date: "Feb 16",
    selected: true,
  },
];
