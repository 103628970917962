import axiosClient from "../../../../utils/api";

export const setFavorite = async (payload) => {
    try {
      const response = await axiosClient().request({
        url: "/favorites",
        method: "post",
        data: payload,
      });
      return response.data;
    } catch (error) {
      console.log("Error at Update Email Service", error);
      throw error;
    }
  };

export const deleteFavorite = async (payload) => {
    try {
      const response = await axiosClient().request({
        url: `/favorites/${payload}`,
        method: "delete",
      });
      return response.data;
    } catch (error) {
      console.log("Error at Delete Favorite Service", error);
      throw error;
    }
  };
