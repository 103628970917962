import { useDispatch } from 'react-redux';
import { openToast } from '../../store/slices/common/toast';

export const useIssueApiCall = (onError) => {
  const dispatch = useDispatch();

  const executeApiCall = async (actionFunction) => {
    try {
      const result = await dispatch(actionFunction).unwrap();
      return result;
    } catch (error) {
      if (onError) {
        onError(error);
      }
      
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data?.message || error?.message || "Operation failed",
        })
      );
    }
  };

  return executeApiCall;
};
