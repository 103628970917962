import React from 'react';

const ForwardButton = ({ width = 28, height = 28, color = "white", onClick }) => {
  return (
    <svg 
      width={width} 
      height={height} 
      viewBox="0 0 28 28" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path d="M2.33333 9.73001V18.27C2.33333 20.02 4.23499 21.1167 5.75166 20.2417L9.45 18.1183L13.1483 15.9834C13.3817 15.8434 13.5683 15.6917 13.72 15.505V12.5184C13.5683 12.3317 13.3817 12.18 13.1483 12.04L9.45 9.905L5.75166 7.7817C4.23499 6.88336 2.33333 7.98001 2.33333 9.73001Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.72 9.73001V18.27C13.72 20.02 15.6217 21.1167 17.1383 20.2417L20.8367 18.1183L24.535 15.9834C26.0517 15.1084 26.0517 12.9267 24.535 12.04L20.8367 9.905L17.1383 7.7817C15.6217 6.88336 13.72 7.98001 13.72 9.73001Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default ForwardButton;
