import React from "react";
import { Grid, Box, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import BookmarkMediaCard from "../BookmarkMediaCard";
import InfiniteScroll from "../../../../atoms/InfiniteScroll";
import NoPostsForBookmark from "../../../../molecules/web/bookmarks/NoPostsForBookmark";

const BookmarkMediaGrid = ({ getData }) => {
  const { media, loading, hasNextPage, nextCursor, isInitialMediaLoaded } = useSelector(
    (state) => state.bookmarkMedia
  );

  const handleLoadMore = () => {
    if (!hasNextPage || loading) return;
    getData(nextCursor);
  };

  return (
    <Box className="light-gray-bg lg:mr-4 creator-home-module mb-4">
      <Grid container spacing={2} sx={{ p: 2 }}>
        {media.map((item) => (
          <Grid 
            item 
            xs={6} 
            md={3} 
            key={item.id}
            sx={{
              minWidth: '200px',
              margin: '8px',
            }}
          >
            <BookmarkMediaCard media={item} />
          </Grid>
        ))}
      </Grid>

      <InfiniteScroll
        onLoadMore={handleLoadMore}
        loading={loading}
        isInitialDataLoaded={isInitialMediaLoaded}
        dataLength={media.length}
        loadingComponent={
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
            <CircularProgress size={32} />
          </Box>
        }
      />

      {isInitialMediaLoaded && media.length === 0 && (
        <NoPostsForBookmark />
      )}
    </Box>
  );
};

export default BookmarkMediaGrid;
