import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Logo from "./../../../assets/images/logo.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomLoader } from "../../../newComponents/atoms";
import { verifyIdentityAction } from "../../../store/middlewares/auth/session/auth";
import { languages } from "../../../utils/languages";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function VerifyIdentity() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const { verifyIdentityError, verifyIdentitySuccess, verifyIdentityLoading } =
    useSelector((state) => state.webauth);

  useEffect(() => {
    if (token) {
      dispatch(verifyIdentityAction(token));
    }
  }, [token, dispatch]);

  // ------------- Login Redirection -------------
  useEffect(() => {
    if (verifyIdentitySuccess) {
      const updatedUserDetails = {
        ...JSON.parse(localStorage.getItem("user-details")),
        isIdentityVerified: true,
      };
      localStorage.setItem("user-details", JSON.stringify(updatedUserDetails));
      navigate("/");
    }
  }, [verifyIdentitySuccess]);

  return (
    <div className="auth-page">
      <Grid container spacing={2} className="OTP-page h-full">
        {!isMobile && (
          <Grid item xs={6} md={6} lg={8} className="auth-sec-img">
            <div className="auth-bg"></div>
          </Grid>
        )}
        <Grid item xs={12} md={6} lg={4} className="max-h-auto">
          <Box className="flex justify-center flex-col h-full sm:p-12 p-8 lg:pl-8 md:pl-16 sm:pl-16 pl-12">
            <img
              src={Logo}
              alt="logo"
              className="mb-16 mx-auto pointer-events-none"
            />
            {verifyIdentityError && (
              <Box className="card mt-5 py-10">
                <div className="card-body items-center justify-center flex flex-col">
                  <h3>Please try again, or contact our support team.</h3>
                  <NavLink className="mt-4" to="/">
                    {languages["en"].LogIn}
                  </NavLink>
                </div>
              </Box>
            )}
            {verifyIdentityLoading && <CustomLoader />}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
