import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  media: [],
  loading: false,
  hasNextPage: false,
  nextCursor: null,
  isInitialMediaLoaded: false,
  error: null
};

const bookmarkMediaSlice = createSlice({
  name: "bookmarkMedia",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setMedia: (state, action) => {
      state.media = action.payload;
      state.isInitialMediaLoaded = true;
    },
    appendMedia: (state, action) => {
      state.media = [...state.media, ...action.payload];
    },
    setPagination: (state, action) => {
      state.hasNextPage = action.payload.hasNextPage;
      state.nextCursor = action.payload.nextCursor;
    },
    resetMedia: (state) => {
      state.media = [];
      state.hasNextPage = false;
      state.nextCursor = null;
      state.isInitialMediaLoaded = false;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const {
  setLoading,
  setMedia,
  appendMedia,
  setPagination,
  resetMedia,
  setError
} = bookmarkMediaSlice.actions;

export default bookmarkMediaSlice.reducer;
