import axiosClient from "../../../../utils/api";
// eslint-disable-next-line no-undef
const URI = process.env.REACT_APP_API_URL + "/admin/auth";

export const loginAdmin = async (payload) => {
  const URL = `${URI}/login`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const changePassword = async (payload) => {
  const URL = `${URI}/changePassword`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Change Password:", error);
    throw error;
  }
};
