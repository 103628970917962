import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { StarIcon } from "../../../assets/SVG/StarIcon";
import { getColor } from "../../../utils/colors";
import { setFavorite, deleteFavorite } from "../../../store/services/web/profile/favorites.service";
import { openToast } from "../../../store/slices/common/toast";
import { isGuestUser } from "../../../utils/isGuestUser";

const FavoriteUserIconButton = ({
  initialFavorite = false,
  userId,
  disabled = false
}) => {
  const [isFavorite, setIsFavorite] = useState(initialFavorite);
  const [isDisabled, setIsDisabled] = useState(disabled);
  const dispatch = useDispatch();

  const handleFavorite = async () => {
    if (isGuestUser(dispatch)) {
      return;
    }

    setIsDisabled(true);
    try {
      if (isFavorite) {
        await deleteFavorite(userId);
      } else {
        await setFavorite({ userId });
      }
      setIsFavorite(!isFavorite);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: "Failed to set favorite",
        })
      );
    }
    setIsDisabled(false);
  };

  return (
    <IconButton
      aria-label="favorite"
      disabled={isDisabled}
      sx={{ padding: "0px !important" }}
      onClick={handleFavorite}
    >
      <StarIcon 
        type={isFavorite ? "filled" : "outline"} 
        color={getColor("primary")} 
      />
    </IconButton>
  );
};

export default FavoriteUserIconButton;
