import React, { useEffect, useState, useCallback } from "react";
import InfiniteScroll from "../../../../atoms/InfiniteScroll";
import { getFilteredUserList, getUserListCollectionUsers } from "../../../../../store/services/admin/users/users";
import { setFilteredUsers, setUsersLoading, resetUsers } from "../../../../../store/slices/common/users";
import { useSelector, useDispatch } from "react-redux";
import ScreenMirroringIcon from "./../../../../../assets/images/screenmirroring.svg";
import ActiveUserIcon from "./../../../../../assets/images/active-user.svg";
import TimerIcon from "./../../../../../assets/images/timer.svg";
import BlockedUserIcon from "./../../../../../assets/images/blockuserIcon.svg";
import Masonry from "@mui/lab/Masonry";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, CircularProgress, Grid, Tab } from "@mui/material";
import UserListFolders from "../../../../molecules/web/userLists/UserListFolders";
import DisplayUserInCollection from "../../../../molecules/web/userLists/DisplayUserInCollection";
import { NoItemsInCollection } from "../../../../molecules";

const UsersTab = ({ active, setActive }) => {
  const [selectedTab, setSelectedTab] = useState("all");
  const dispatch = useDispatch();
  const { users, usersLoading, hasNextPage, nextCursor } = useSelector((state) => state.users);
  const [usersToDisplay, setUsersToDisplay] = useState([]);

  const loadUsers = useCallback(async (append = false) => {
    if (append && (!hasNextPage || usersLoading)) {
      return;
    }
    
    if (!append) {
      dispatch(setUsersLoading(true));
    }
    try {
      if (selectedTab === "all") {
        if (!active?.id) return;
        const response = await getUserListCollectionUsers({
          folderId: active?.id,
          cursor: append ? nextCursor : undefined
        });
        dispatch(setFilteredUsers({
          users: response.response.users,
          nextCursor: response.response.nextCursor,
          hasNextPage: response.response.hasNextPage,
          append
        }));
      } else {
        const filterTypeMap = {
          active: 'subscribedFromMeActive',
          expired: 'subscribedFromMeExpired',
          restricted: 'restrictedByMe',
          blocked: 'blockedByMe',
          favorites: 'favoritedByMe',
          fans: 'subscribedToMe'
        };
        
        const response = await getFilteredUserList({
          filterType: filterTypeMap[selectedTab],
          cursor: append ? nextCursor : undefined
        });
        
        dispatch(setFilteredUsers({
          users: response.response.users,
          nextCursor: response.response.nextCursor,
          hasNextPage: response.response.hasNextPage,
          append
        }));
      }
    } catch (error) {
    } finally {
      dispatch(setUsersLoading(false));
    }
  }, [selectedTab, active?.id, nextCursor, hasNextPage, usersLoading, dispatch]);

  useEffect(() => {
    dispatch(resetUsers());
    loadUsers(false);
  }, [selectedTab, active?.id]); 

  useEffect(() => {
    setUsersToDisplay(users);
  }, [users]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <TabContext value={selectedTab}>
      <Box className="mb-5">
        <TabList
          onChange={handleChange}
          aria-label="user tabs"
          className="user-tabs"
        >
          <Tab
            icon={<img src={ScreenMirroringIcon} alt="All"/>}
            label={`All (${
              active?.userCount < 10
                ? `0${active?.userCount}`
                : active?.userCount 
                ? active?.userCount
                : 0
            })`}
            value="all"
          />
          <Tab
            icon={<img src={ActiveUserIcon} alt="Active"/>}
            label={`Active (${
              active?.totalActiveUser < 10
                ? `0${active?.totalActiveUser}`
                : active?.totalActiveUser
                ? active?.totalActiveUser
                : 0
            })`}
            value="active"
          />
          <Tab
            icon={<img src={TimerIcon} alt="Expired"/>}
            label={`Expired (${
              active?.totalExpiredUser < 10
                ? `0${active?.totalExpiredUser}`
                : active?.totalExpiredUser
                ? active?.totalExpiredUser
                : 0
            })`}
            value="expired"
          />
          <Tab
            icon={<img src={BlockedUserIcon} alt="Restricted"/>}
            label={`Restricted (${
              active?.totalRestrictedUser < 10
                ? `0${active?.totalRestrictedUser}`
                : active?.totalRestrictedUser
                ? active?.totalRestrictedUser
                : 0
            })`}
            value="restricted"
          />
          <Tab
            icon={<img src={BlockedUserIcon} alt="Blocked"/>}
            label={`Blocked (${
              active?.totalBlockedUser < 10
                ? `0${active?.totalBlockedUser}`
                : active?.totalBlockedUser
                ? active?.totalBlockedUser
                : 0
            })`}
            value="blocked"
          />
          <Tab label="Favorites" value="favorites" />
          <Tab label="Fans" value="fans" />
        </TabList>
      </Box>
      
      <Grid container spacing={1} className="h-full mb-4 fans-message">
        <Grid item xs={12} md={12} lg={8}>
          <div className="light-gray-bg lg:mr-4 post-no-padding  mb-4">
            <TabPanel value={selectedTab}>
              <div className="h-full mb-4 fans-message">
                {usersLoading ? (
                  <div className="py-12 w-full rounded-2xl flex justify-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    {usersToDisplay?.length === 0 ? (
                      <NoItemsInCollection />
                    ) : (
                      <Masonry
                        columns={{ xs: 1, sm: 1, md: 1, lg: 2 }}
                        spacing={2}
                      >
                        {usersToDisplay?.map((user, index) => {
                          return (
                            <DisplayUserInCollection
                              key={index}
                              user={user}
                              index={index}
                              folderId={active?.id}
                            />
                          );
                        })}
                      </Masonry>
                    )}
                  </>
                )}
              </div>
              <InfiniteScroll
                onLoadMore={() => loadUsers(true)}
                loading={usersLoading}
                isInitialDataLoaded={true}
                dataLength={usersToDisplay.length}
                threshold={0.5}
              />
            </TabPanel>
          </div>
        </Grid>

        {selectedTab === "all" && (
          <UserListFolders
            mediaType={selectedTab}
            type={"users"}
            active={active}
            setActive={setActive}
            users={users}
          />
        )}
      </Grid>
    </TabContext>
  );
};

export default UsersTab;
