import React, { useState, useEffect, useRef } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getColor } from "../../../../../../utils/colors";
import { useDispatch } from "react-redux";
import { openToast } from "../../../../../../store/slices/common/toast/index";
import { formatDuration } from "../../../../../../utils/formatDuration";

const AudioPlayer = ({ src, onUnsupportAudio, onDurationChange }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [waveformData, setWaveformData] = useState([]);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const audioRef = useRef(null);
  const canvasRef = useRef(null);
  const audioContextRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const updateTime = () => setCurrentTime(audio.currentTime);
    const updateDuration = () => {
      if (isFinite(audio.duration) && audio.duration > 0) {
        const newDuration = audio.duration;
        setDuration(newDuration);
        if (onDurationChange) {
          onDurationChange(newDuration);
        }
      }
    };

    audio.addEventListener("timeupdate", updateTime);
    audio.addEventListener("loadedmetadata", updateDuration);
    audio.addEventListener("durationchange", updateDuration);
    audio.addEventListener("ended", () => setIsPlaying(false));

    return () => {
      audio.removeEventListener("timeupdate", updateTime);
      audio.removeEventListener("loadedmetadata", updateDuration);
      audio.removeEventListener("durationchange", updateDuration);
      audio.removeEventListener("ended", () => setIsPlaying(false));
    };
  }, [onDurationChange]);

  useEffect(() => {
    if (waveformData.length > 0) {
      drawWaveform();
    }
  }, [waveformData, currentTime]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackSpeed;
    }
  }, [playbackSpeed]);

  useEffect(() => {
    initializeAudioContext();
  }, [src]);

  const initializeAudioContext = async () => {
    if (!audioContextRef.current) {
      try {
        audioContextRef.current = new (window.AudioContext ||
          window.webkitAudioContext)();
        await analyzeAudio();
      } catch (error) {
        console.error("Failed to initialize AudioContext:", error);
        dispatch(
          openToast({
            type: "error",
            message: "Failed to initialize audio player",
          })
        );
      }
    }
  };

  const togglePlay = async () => {
    if (audioRef.current) {
      try {
        if (audioContextRef.current?.state === "suspended") {
          await audioContextRef.current.resume();
        }

        if (isPlaying) {
          audioRef.current.pause();
        } else {
          await audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
      } catch (error) {
        console.error("Playback failed:", error);
        dispatch(
          openToast({
            type: "error",
            message: "Failed to play audio",
          })
        );
      }
    }
  };

  const analyzeAudio = async () => {
    const audio = audioRef.current;
    if (!audio || !audioContextRef.current) return;

    try {
      const response = await fetch(src);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioContextRef.current.decodeAudioData(
        arrayBuffer
      );

      const channelData = audioBuffer.getChannelData(0);
      const samples = 50;
      const blockSize = Math.floor(channelData.length / samples);
      const filteredData = [];

      for (let i = 0; i < samples; i++) {
        const blockStart = blockSize * i;
        let blockSum = 0;
        for (let j = 0; j < blockSize; j++) {
          blockSum += Math.abs(channelData[blockStart + j]);
        }
        filteredData.push(blockSum / blockSize);
      }

      const multiplier = Math.pow(Math.max(...filteredData), -1);
      const normalizedData = filteredData.map((n) => n * multiplier);

      setWaveformData(normalizedData);
    } catch (error) {
      console.error("Audio analysis failed:", error);
      dispatch(
        openToast({
          type: "error",
          message:
            "Unable to process audio file. The file might be corrupted or in an unsupported format.",
        })
      );
      if (onUnsupportAudio) {
        onUnsupportAudio();
      }
    }
  };

  const drawWaveform = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const width = canvas.width;
    const height = canvas.height;
    ctx.clearRect(0, 0, width, height);

    const barWidth = 4;
    const barGap = 2;
    const barCount = waveformData.length;

    const progress = currentTime / duration;
    const progressBarCount = Math.floor(barCount * progress);

    for (let i = 0; i < barCount; i++) {
      const barHeight = waveformData[i] * height;
      const x = i * (barWidth + barGap);
      const y = (height - barHeight) / 2;

      if (i < progressBarCount) {
        ctx.fillStyle = "rgba(255,255,255, 1)";
      } else {
        ctx.fillStyle = "rgba(255,255,255, 0.5)";
      }

      ctx.fillRect(x, y, barWidth, barHeight);
    }
  };

  const handleWaveformClick = (event) => {
    const canvas = canvasRef.current;
    const audio = audioRef.current;
    if (!canvas || !audio || !isFinite(duration) || duration <= 0) return;

    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const seekPercentage = x / rect.width;

    const newTime = seekPercentage * duration;
    if (isFinite(newTime) && newTime >= 0 && newTime <= duration) {
      audio.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const handleSpeedChange = (speed) => {
    setPlaybackSpeed(speed);
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className="text-white p-2 rounded-xl flex flex-col space-y-2 max-w-md"
      style={{ backgroundColor: getColor("primary") }}
    >
      <div className="flex items-center space-x-2">
        <IconButton
          onClick={togglePlay}
          color="inherit"
          size="small"
        >
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
        <div className="flex-grow relative">
          <canvas
            ref={canvasRef}
            width={300}
            height={24}
            className="w-full h-6 rounded-full overflow-hidden cursor-pointer"
            onClick={handleWaveformClick}
          />
        </div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          color="inherit"
          size="small"
        >
          <MoreVertIcon />
          <span className="text-xs ml-1">{playbackSpeed}x</span>
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {[0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map((speed) => (
            <MenuItem key={speed} onClick={() => handleSpeedChange(speed)}>
              {speed}x
            </MenuItem>
          ))}
        </Menu>
      </div>
      <div className="flex justify-between px-2">
        <div className="text-xs font-medium text-gray-300 ml-3">{formatDuration(currentTime)}</div>
        <div className="text-xs font-medium text-gray-300 mr-3">{formatDuration(duration)}</div>
      </div>
      <audio ref={audioRef} src={src} />
    </div>
  );
};

export default AudioPlayer;
