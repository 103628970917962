import { Button } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import { email as emailSchema } from '../../../../../../utils/validation/ChangeEmailForm'
import { updateMeEmailAction } from "../../../../../../store/middlewares/web/profile/myProfile/me"
import { Input } from '../../../../../atoms';
import { closeModal } from '../../../../../../store/slices/common/modal';
import { useIssueApiCall } from '../../../../../../hooks/backendApiCalls/useIssueAnApiCall';

const ModalChangeEmail = () => {
    const { me } = useSelector((state) => state?.me)
    const dispatch = useDispatch()

    const [email, setEmail] = useState(me?.email ? me?.email : '')

    const executeApiCall = useIssueApiCall((error) => {
        formik.resetForm();
      });

    useEffect(() => {
        setEmail(me?.email ? me?.email : '') //set email of the current logged in user after updating
    }, [me, open])

    const schema = Yup.object({
        email: emailSchema //validation 
    })

    const handleClose = () => {
        formik.resetForm() // reset form on Modal Close
        dispatch(closeModal())
    }

    const handleSubmit = (values) => {
        executeApiCall(updateMeEmailAction(values))
    }

    // form initalize and sewt default Value
    const formik = useFormik({
        initialValues: { email: email },
        enableReinitialize: true,
        validationSchema: schema,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: handleSubmit,
    })

    return (

        <form onSubmit={formik.handleSubmit}>
            <Input
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder='Enter Email'
                onBlur={formik.handleBlur}
                id="email"
                error={formik.errors.email && formik.touched.email}
                errorMsg={formik.errors.email}
            />

            <div className='flex justify-end mt-6 gap-3'>
                <Button variant="contained" className='m-auto white-btn' onClick={handleClose}>Cancel</Button>
                <Button type="submit" variant="contained" className='m-auto px-12'>Save</Button>
            </div>
        </form>
    )
}

export default ModalChangeEmail