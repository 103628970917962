import React from "react";
import DisplayPost from "../../common/DisplayPost";
import { NoPostsForBookmark } from "../../../../molecules";
import { useSelector } from "react-redux";
import LoadMorePosts from "../../common/posts/LoadMorePosts";

const DisplayPostsInBookmark = ({ getData }) => {

  const { postsLoading, posts, postsHasNextPage, nextPostsCursor } = useSelector((state) => state.posts);

  const handleLoadMore = () => {
    if (!postsHasNextPage) return;
    if (postsLoading) return;
    getData(nextPostsCursor);
  };

  return (
    <div
      className={`light-gray-bg lg:mr-4 creator-home-module post-no-padding mb-4 `}
    >
      <>
        {posts?.map(post => (
          <DisplayPost key={post.id} post={post} />
        ))}
        {(posts.length === 0) && <NoPostsForBookmark />}
      </>
      <LoadMorePosts onLoadMore={handleLoadMore} loading={postsLoading} />
    </div>
  );
};

export default DisplayPostsInBookmark;
