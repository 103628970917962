import axiosClient from "../../../../utils/api";

export const getMe = async () => {
  try {
    const response = await axiosClient().request({
      url: "/profile/me",
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get ME Service", error);
    throw error;
  }
};

export const updateMe = async (payload) => {
  let headers = {
    "Content-Type": "multipart/form-data",
  };
  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    formData.append(`${key}`, payload[`${key}`]);
  });
  try {
    const response = await axiosClient().request({
      url: "/profile/updateProfile",
      method: "post",
      data: formData,
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Update Account Service", error);
    throw error;
  }
};

export const getUserProfile = async (params) => {
  try {
    const response = await axiosClient().request({
      url: "/profile/userProfile",
      method: "get",
      params: params,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get Other User Profile Service", error);
    throw error;
  }
};

export const blockUserProfile = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: "/blockUser/add",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get Block User Profile Service", error);
    throw error;
  }
};

export const blockUserSuggestion = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: "/blockUser/add",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get Block User Profile Service", error);
    throw error;
  }
};

export const deleteAccount = async () => {
  try {
    const response = await axiosClient().request({
      url: "/profile/deleteAccount",
      method: "post",
      data: null,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Delete Account Service", error);
    throw error;
  }
};

export const getCountryCodeForWeb = async () => {
  try {
    const response = await axiosClient().request({
      url: "/profile/getCountry",
      method: "post",
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get Country Code Service", error);
    throw error;
  }
};

export const getStateForWeb = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: "/profile/getStates",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get State Service", error);
    throw error;
  }
};
