export const STORY_MAX_IMAGES = 10;
export const STORY_MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB in bytes
export const STORY_DURATION = 5000; //5s

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const MediaType = {
  Video: 'video',
  Image: 'image',
  Audio: 'audio',
  Gif: 'gif',
};