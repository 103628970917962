import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AnswerQuizAction } from "../../../../../store/middlewares/web/post/posts";
import GrayDot from "./../../../../../assets/images/dot.svg";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Close, Done } from "@mui/icons-material";
import { getFirstAnsweredId } from "../../../../../utils/post";
import { useOptionPostExpiry } from "../../../../../hooks/post/useOptionPostExpiry";

const DisplayQuizInPost = ({
  options,
  postId,
  isLoggedInUser,
  expiry,
  quizAnswersCount,
  isCreator,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [sortedOptions, setSortedOptions] = useState([]);

  useEffect(() => {
    if (options?.length > 0) {
      const sorted = [...options].sort((a, b) => {
        const seqA = parseInt(a.sequence);
        const seqB = parseInt(b.sequence);
        return seqA - seqB;
      });
      setSortedOptions(sorted);
      setSelectedAnswer(getFirstAnsweredId(options));
    }
  }, [options]);

  const { isPostExpired, timeLeftText } = useOptionPostExpiry(expiry);
  const styleAnswered = (option) =>
    option?.isCorrectAnswer !== null &&
    option?.isCorrectAnswer !== undefined &&
    option?.isCorrectAnswer
      ? "text-green-500"
      : !option?.isCorrectAnswer && option?.hasAnswered
      ? "text-red-500"
      : "";

  const dispatch = useDispatch();
  const handleQuizChange = (e, optionId) => {
    setSelectedAnswer(e.target.value);
    dispatch(AnswerQuizAction({ postId: postId, quizOptionId: optionId }));
  };
  return (
    <div className="mt-4 border rounded-lg">
      {!isCreator &&
      (isPostExpired ||
        (expiry && expiry === null) ||
        (isLoggedInUser && selectedAnswer !== null && selectedAnswer)) ? (
        <div className="flex flex-col">
          {sortedOptions?.map((option) => {
            return (
              <div
                key={option.id}
                value={option.optionText}
                className={"border-b p-2"}
              >
                <div
                  className={`flex p-2 ${
                    selectedAnswer?.id === option.id
                      ? "light-gray-bg rounded-lg"
                      : ""
                  } ${
                    selectedAnswer || isLoggedInUser
                      ? "justify-between"
                      : "justify-start"
                  }`}
                >
                  <div className="flex gap-4">
                    {!option?.isCorrectAnswer ? (
                      <Close color="error" />
                    ) : (
                      <Done color="success" />
                    )}
                    <p>{option.optionText}</p>
                  </div>
                  {isLoggedInUser && <div>{option?.answers?.length}</div>}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={selectedAnswer}
          name="radio-buttons-group"
        >
          {sortedOptions?.map((option) => {
            return (
              <FormControlLabel
                key={option.id}
                value={option.id}
                control={
                  <Radio
                    className={`p-0`}
                    value={option.id}
                    onChange={(e) => {
                      handleQuizChange(e, option.id);
                    }}
                    checked={option?.id === selectedAnswer}
                    disabled={selectedAnswer}
                  />
                }
                label={
                  <div className="flex justify-between items-center w-full relative">
                    <p
                      className={`pl-2 relative z-10 ${styleAnswered(option)}`}
                    >
                      {option.optionText}
                    </p>
                    {option?.answersCount !== null &&
                      option?.answersCount !== undefined && (
                        <p> {option?.answersCount} Answers</p>
                      )}
                  </div>
                }
                className={"border-b p-4"}
              />
            );
          })}
        </RadioGroup>
      )}

      <div className="py-2 pl-3 rounded-b-lg light-gray-bg flex items-center">
        <p>QUIZ</p>
        <img src={GrayDot} alt="GrayDot" className="mx-2 gray-dot-width" />
        <p>{quizAnswersCount} Answers</p>
        {expiry && (
          <>
            <img src={GrayDot} alt="GrayDot" className=" mx-2 gray-dot-width" />
            {!isPostExpired ? <p>{timeLeftText} </p> : <p>Expired</p>}
          </>
        )}
      </div>
    </div>
  );
};

export default DisplayQuizInPost;
