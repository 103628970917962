import React from "react";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { closeCustomizableModal, onVaultMediaSelect } from "../../../../../store/slices/common/customizableModal";
import { CloseImageIconButton } from "../../../../../assets/SVG/CloseImageIconButton";
import Vault from "../../../../../pages/web/creatorSide/Vault";
import { Box, IconButton } from "@mui/material";

const CreatorsVaultModal = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeCustomizableModal());
  };

  const handleMediaSelect = (files) => {
    dispatch(onVaultMediaSelect(files));
    setTimeout(() => {
      dispatch(closeCustomizableModal());
    }, 0);
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      maxWidth={false}
      fullScreen
      PaperProps={{
        sx: {
          m: '4%', // 4% margin
          width: '92%', // 100% - (2 * 4%)
          height: '92%', // 100% - (2 * 4%)
          bgcolor: 'background.paper',
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          position: 'relative',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
        }}
      >
        Creators Vault
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseImageIconButton />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Vault onAddMedia={handleMediaSelect} isModal={true} />
      </DialogContent>
    </Dialog>
  );
};

export default CreatorsVaultModal;
