import { Grid, IconButton } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { openModal } from "../../../../../store/slices/common/modal";
import ModalDeleteUserListFolder from "../ModalDeleteUserListFolder";
import { Delete } from "@mui/icons-material";
import listmenublack from "./../../../../../assets/images/listmenublack.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUserListFolderAction } from "../../../../../store/middlewares/web/folders/folders";
import { getUsersCollectionForUserListAction } from "../../../../../store/middlewares/admin/users/users";
import { getPostsCollectionForUserListAction } from "../../../../../store/middlewares/web/post/posts";
import NoItemsInCollection from "../NoItemsInCollection";

const UserListFolders = ({ mediaType, type, active, setActive }) => {
  const { folders } = useSelector((state) => state.folders);
  const dispatch = useDispatch();
  const getFolderDetails = useCallback(() => {
    dispatch(getUserListFolderAction());
  }, []);

  useEffect(() => {
    if (folders?.length > 0) {
      setActive(folders[0]);
    }
  }, [folders]);

  const getData = useCallback(() => {
    if (active) {
      if (type === "users") {
        dispatch(
          getUsersCollectionForUserListAction({
            filterType: mediaType,
            folderId: active?.id,
          })
        );
      }
      if (type === "posts") {
        dispatch(
          getPostsCollectionForUserListAction({
            filterType: mediaType,
            folderId: active?.id,
          })
        );
      }
    }
  }, [dispatch, mediaType, active, type]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getFolderDetails();
  }, [getFolderDetails]);

  return (
    <Grid item xs={12} md={12} lg={4}>
      <div className="light-gray-bg p-4">
        <div className="flex justify-between flex-wrap gap-3 border-b pb-3">
          <p className="font-18 font-medium">Customer Order</p>
          <img
            src={listmenublack}
            alt="listmenublack"
            width={20}
            className="img-dark-filter"
          />
        </div>

        {folders?.map((folder, index) => {
          return (
              <div
                key={`${folder?.folderName}-${index}`}
                className={`${active?.id === folder?.id ? "active" : ""
                  } customer-box mt-5`}
                onClick={() => setActive(folder)}
              >
                <div className="flex justify-between items-start gap-2 flex-wrap">
                  <div className="w-1/2">
                    <p className="text-darkblue font-medium">
                      {folder?.folderName}
                    </p>
                  </div>
                  <div className="flex gap-2 flex-wrap">
                    <p className="font-14 font-medium">
                      {!folder?.userCount ? 0 : folder.userCount} Users{" "}
                      <span className="mx-2">|</span>{" "}
                      {!folder?.totalPosts ? 0 : folder.totalPosts} Posts
                    </p>

                    <IconButton
                      onClick={() =>
                        dispatch(
                          openModal({
                            title: "DELETE",
                            hasCloseBtn: true,
                            content: (
                              <ModalDeleteUserListFolder folderId={folder?.id} />
                            ),
                          })
                        )
                      }
                      disableRipple
                      sx={{ padding: "0" }}
                    >
                      <Delete color="error" />
                    </IconButton>
                  </div>
                </div>
              </div>
          );
        })}
        {folders?.length === 0 && <NoItemsInCollection />}
      </div>
    </Grid>
  );
};

export default UserListFolders;
