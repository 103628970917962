import React from "react";
import AllIcon from "../assets/SVG/VaultTabs/AllIcon.js";
import GifIcon from "../assets/SVG/VaultTabs/GifIcon.js";
import VideoIcon from "../assets/SVG/VaultTabs/VideoIcon.js";
import PhotoIcon from "../assets/SVG/VaultTabs/PhotoIcon.js";
import AudioIcon from "../assets/SVG/VaultTabs/AudioIcon.js";

const vaultTabs = [
    {
        "icon": <AllIcon />,
        "label": "All",
        "value": "0"
    },
    {
        "icon": <PhotoIcon />,
        "label": "Photo",
        "value": "1"
    },
    {
        "icon": <GifIcon />,
        "label": "Gif",
        "value": "2"
    },
    {
        "icon": <VideoIcon />,
        "label": "Video",
        "value": "3"
    },
    {
        "icon": <AudioIcon />,
        "label": "Audio ",
        "value": "4"
    },

]

export default vaultTabs

