import React, { useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

const InfiniteScroll = ({
  onLoadMore,
  loading,
  isInitialDataLoaded,
  dataLength,
  threshold = 0.1,
  loadingComponent,
  className = "flex justify-center py-4"
}) => {
  const loaderRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        
        if (entry.isIntersecting && !loading) {
          if (isInitialDataLoaded && dataLength > 0) {
            onLoadMore();
          }
        }
      },
      { threshold }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [onLoadMore, loading, isInitialDataLoaded, dataLength, threshold]);

  return (
    <div ref={loaderRef} className={className}>
      {loading && (loadingComponent || <CircularProgress />)}
    </div>
  );
};

InfiniteScroll.propTypes = {
  onLoadMore: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isInitialDataLoaded: PropTypes.bool.isRequired,
  dataLength: PropTypes.number.isRequired,
  threshold: PropTypes.number,
  loadingComponent: PropTypes.node,
  className: PropTypes.string
};

export default InfiniteScroll;
