import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
  modalId: null,
  modalData: null,
  selectedVaultMedia: null
};

export const customizableModalSlice = createSlice({
  name: "customizableModal",
  initialState,
  reducers: {
    openCustomizableModal: (state, { payload }) => {
      state.modalId = payload?.modalId;
      state.modalData = payload?.modalData;
    },
    closeCustomizableModal: (state) => {
      return initialState;
    },
    onVaultMediaSelect: (state, { payload }) => {
      state.selectedVaultMedia = payload;
    }
  },
});

// Base selector
const selectCustomizableModal = (state) => state.customizableModal;

// Memoized selector
export const selectCustomizableModalState = createSelector(
  [selectCustomizableModal],
  (modal) => ({
    modalId: modal.modalId,
    modalData: modal.modalData,
    selectedVaultMedia: modal.selectedVaultMedia
  })
);

export const { openCustomizableModal, closeCustomizableModal, onVaultMediaSelect } = customizableModalSlice.actions;

export default customizableModalSlice.reducer;
