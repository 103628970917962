import React, { useRef, useEffect, useState } from 'react';
import styles from './styles.module.css';
import Timeline from './Timeline';
import VolumeControl from './VolumeControl';
import PlaybackControls from './PlaybackControls';
import OptionsControls from './OptionsControls';

const Controls = ({
  isPlaying,
  volume,
  isMuted,
  currentTime,
  duration,
  isFullscreen,
  isMiniplayer,
  showControls,
  onPlay,
  onVolumeChange,
  onMuteToggle,
  onTimelineChange,
  onFullscreenToggle,
  onMiniplayerToggle,
  onNext,
  onPrevious,
  formatTime
}) => {
  const controlsRef = useRef(null);
  const [iconSize, setIconSize] = useState(20);

  useEffect(() => {
    const updateDimensions = () => {
      if (!controlsRef.current) return;
      const width = controlsRef.current.offsetWidth;
      const newIconSize = isFullscreen ? 28 : Math.max(16, Math.min(20, width / 40));
      setIconSize(newIconSize);
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [isFullscreen]);

  return (
    <div 
      ref={controlsRef}
      className={`${styles.controls} ${showControls ? styles.visible : ''}`}
    >
      <Timeline
        currentTime={currentTime}
        duration={duration}
        onTimelineChange={onTimelineChange}
        formatTime={formatTime}
      />

      <div className={styles.controlsBar}>
        <div className={styles.leftControls}>
          <VolumeControl
            volume={volume}
            isMuted={isMuted}
            onVolumeChange={onVolumeChange}
            onMuteToggle={onMuteToggle}
            size={iconSize}
          />
        </div>

        <div className={styles.centerControls}>
          <PlaybackControls
            isPlaying={isPlaying}
            onPlay={onPlay}
            onNext={onNext}
            onPrevious={onPrevious}
            size={iconSize}
          />
        </div>

        <div className={styles.rightControls}>
          <OptionsControls
            isFullscreen={isFullscreen}
            isMiniplayer={isMiniplayer}
            onFullscreenToggle={onFullscreenToggle}
            onMiniplayerToggle={onMiniplayerToggle}
            size={iconSize}
          />
        </div>
      </div>
    </div>
  );
};

export default Controls;
