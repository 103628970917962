// src/components/ProfileDisplay.js
import React, { useEffect } from "react";
import { FormControlLabel, Grid, InputAdornment, Switch } from "@mui/material";
import LanguageIcon from "./../../../../../assets/images/language-icon.svg";
import DarkModeIcon from "./../../../../../assets/images/darkmode-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { ProfileButtons, ProfileSectionWrapper } from "../../../../atoms";
import { ModalLanguageSelection } from "../../../../molecules";
import { toggleDarkModeAction } from "../../../../../store/middlewares/web/profile/myProfile/profiledisplay";
import { openModal } from "../../../../../store/slices/common/modal";
import { useTheme } from "../../../../atoms/admin/Context/ThemeContext";

const ProfileDisplay = () => {
  const dispatch = useDispatch();
  const { checked, setChecked, handleToggle } = useTheme();

  const { me } = useSelector((state) => state.me);

  useEffect(() => {
    setChecked(me?.isDarkMode);
  }, [me]);

  const handleChange = () => {
    dispatch(toggleDarkModeAction({ mode: me?.isDarkMode }));
    handleToggle();
  };

  return (
    <Grid container spacing={2} className="fans-profile mt-3 ">
      <Grid item xs={12} md={12} lg={12} className="mb-4">
        <div className="bg-white rounded-2xl py-3 px-5 h-calc-200">
          <h3 className="font-semibold text-darkblue font-24 mb-3">Display</h3>

          <ProfileSectionWrapper title={"Customize Your View"}>
            <Grid container spacing={2} className="fans-profile account-info">
              {/* <ProfileButtons
                endAdornment={
                  <img
                    src={LanguageIcon}
                    alt="LanguageIcon"
                    onClick={() => {
                      dispatch(
                        openModal({
                          title: "LANGUAGE",
                          content: <ModalLanguageSelection />,
                          hasClostBtn: true,
                        })
                      );
                    }}
                  />
                }
                title={"Languages (English)"}
                hasOneValue={false}
              /> */}
              <ProfileButtons
                hasOneValue={false}
                startAdornment={
                  <InputAdornment position="end">
                    <img src={DarkModeIcon} alt="DarkModeIcon" />
                  </InputAdornment>
                }
                endAdornment={
                  <FormControlLabel
                    className="black-border"
                    control={
                      <Switch
                        checked={checked}
                        onChange={(e) => handleChange(e)}
                      />
                    }
                  />
                }
                title={"Dark Mode"}
              />
            </Grid>
          </ProfileSectionWrapper>
        </div>
      </Grid>
    </Grid>
  );
};

export default ProfileDisplay;
