import React from 'react';

const PlayButton = ({ width = 50, height = 50, color = "white", onClick }) => {
  return (
    <svg 
      width={width} 
      height={height} 
      viewBox="0 0 50 50" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path d="M24.9375 45.8337C36.4434 45.8337 45.7708 36.5063 45.7708 25.0003C45.7708 13.4944 36.4434 4.16699 24.9375 4.16699C13.4316 4.16699 4.10416 13.4944 4.10416 25.0003C4.10416 36.5063 13.4316 45.8337 24.9375 45.8337Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.2083 25.4795V22.0003C18.2083 17.667 21.2708 15.8961 25.0208 18.0628L28.0417 19.8128L31.0625 21.5628C34.8125 23.7295 34.8125 27.2711 31.0625 29.4378L28.0417 31.1878L25.0208 32.9378C21.2708 35.1045 18.2083 33.3336 18.2083 29.0003V25.4795Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default PlayButton;
