import axiosClient from "../../../../utils/api";
// eslint-disable-next-line no-undef
const URI = process.env.REACT_APP_API_URL + "/admin/dashboard";

export const getDashboard = async () => {
  const URL = `${URI}/getData`;
  // let user = localStorage.getItem("user-details-admin");
  // let headers = {
  //   Authorization: `Bearer ${JSON.parse(user).token}`,
  // };
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during dashboard:", error);
    throw error;
  }
};