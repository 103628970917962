import React from "react";
// import AddCardsIcon from "../assets/SVG/SideBar/AddCardsIcon";
import BookMarksIcon from "../assets/SVG/SideBar/BookMarksIcon";
// import ExplorePageIcon from "../assets/SVG/SideBar/ExplorePageIcon";
import HomeIcon from "../assets/SVG/SideBar/HomeIcon";
// import LiveStreamIcon from "../assets/SVG/SideBar/LiveStreamIcon";
import MessagesIcon from "../assets/SVG/SideBar/MessagesIcon";
import MoreIcon from "../assets/SVG/SideBar/MoreIcon";
// import MyOrderIcon from "../assets/SVG/SideBar/MyOrderIcon";
import MyProfileIcon from "../assets/SVG/SideBar/MyProfileIcon";
import NotificationIcon from "../assets/SVG/SideBar/NotificationIcon";
import SubscriptionsIcon from "../assets/SVG/SideBar/SubscriptionsIcon";
import UserListsIcon from "../assets/SVG/SideBar/UserListsIcon";
import WishRequestsIcon from "../assets/SVG/SideBar/WishRequestsIcon";
import { returnLanguage } from "./languages";

const iconMap = {
  home: <HomeIcon />,
  notifications: <NotificationIcon />,
  wishRequests: <WishRequestsIcon />,
  allOrder: <WishRequestsIcon />,
  myCreatorShop: <WishRequestsIcon />,
  messages: <MessagesIcon />,
  userLists: <UserListsIcon />,
  bookmarks: <BookMarksIcon />,
  postLabel: <SubscriptionsIcon />,
  vault: <SubscriptionsIcon />,
  queue: <SubscriptionsIcon />,
  archive: <SubscriptionsIcon />,
  statements: <SubscriptionsIcon />,
  statistics: <SubscriptionsIcon />,
  myProfile: <MyProfileIcon />,
  more: <MoreIcon />,
};

const creatorSideBarItems = (lang = "en") => {
  const langObj = returnLanguage(lang);

  const items = [
    { slug: "home", label: langObj?.Home, count: 0 },
    { slug: "notifications", label: langObj?.Notification, count: 3 },
    { slug: "wish-requests", label: langObj?.WishRequest, count: 3 },
    { slug: "all-order", label: langObj?.AllOrder, count: 3 },
    { slug: "my-creator-shop", label: langObj?.MyCreatorShop, count: 2 },
    { slug: "messages", label: langObj?.Messages, count: 0 },
    { slug: "user-lists", label: langObj?.UserLists, count: 0 },
    { slug: "bookmarks", label: langObj?.Bookmarks, count: 0 },
    { slug: "post-label", label: langObj?.PostLabels, count: 0 },
    { slug: "vault", label: langObj?.Vault, count: 0 },
    { slug: "queue", label: langObj?.Queue, count: 0 },
    { slug: "archive", label: "Archive", count: 0 },
    { slug: "statements", label: langObj?.Statements, count: 0 },
    { slug: "statistics", label: langObj?.Statistics, count: 0 },
    { slug: "my-profile", label: langObj?.MyProfile, count: 0 },
    { slug: "more", label: langObj?.More, count: 0 },
    // { slug: "saved-for-later", label: 'Saved For Later', count: 0 },
  ];

  return items.map((item) => ({
    ...item,
    icon: iconMap[item.slug], // Automatsko povezivanje ikone
    hasPage: true,
  }));
};

export default creatorSideBarItems;
